import { useRef } from "react";
import { url } from "../../pages/HomePage";
import "./UserList.css";
import classes from "./DefaultsList.module.css";

export default function DefaultsList2({ defaults }) {

  const sewer_spanInputRef = useRef();
  const sewer_priceInputRef = useRef();
  const plumbing_spanInputRef = useRef();
  const plumbing_priceInputRef = useRef();
  const bathroom_spanInputRef = useRef();
  const bathroom_priceInputRef = useRef();
  const heating_spanInputRef = useRef();
  const heating_priceInputRef = useRef();
  const elec_spanInputRef = useRef();
  const elec_priceInputRef = useRef();
  const vent_spanInputRef = useRef();
  const vent_priceInputRef = useRef();
  const front_spanInputRef = useRef();
  const front_priceInputRef = useRef();
  const yard_spanInputRef = useRef();
  const yard_priceInputRef = useRef();
  const yard_cover_spanInputRef = useRef();
  const yard_cover_priceInputRef = useRef();
  const roof_spanInputRef = useRef();
  const roof_priceInputRef = useRef();
  const elevator_spanInputRef = useRef();
  const elevator_priceInputRef = useRef();
  const rateInputRef = useRef();

  function fetchRequest(index, value) {
    fetch(url + "api/defaults/" + index, {
      method: "put",
      body: value,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      console.log(response);
    });
  }
  async function saveEdit(e) {
    e.preventDefault();
    /* const input = parseInt(document.getElementById("value").innerText); */
    if (Number(sewer_spanInputRef.current.value) !== Number(defaults[7])) {
      fetchRequest(7, sewer_spanInputRef.current.value);
    } if (Number(sewer_priceInputRef.current.value) !== Number(defaults[17])) {
      fetchRequest(17, sewer_priceInputRef.current.value);
    } if (Number(plumbing_spanInputRef.current.value) !== Number(defaults[5])) {
      fetchRequest(5, plumbing_spanInputRef.current.value)
    } if (Number(plumbing_priceInputRef.current.value) !== Number(defaults[15])) {
      fetchRequest(15, plumbing_priceInputRef.current.value)
    } if (Number(bathroom_spanInputRef.current.value) !== Number(defaults[0])) {
      fetchRequest(0, bathroom_spanInputRef.current.value)
    } if (Number(bathroom_priceInputRef.current.value) !== Number(defaults[10])) {
      fetchRequest(10, bathroom_priceInputRef.current.value)
    } if (Number(heating_spanInputRef.current.value) !== Number(defaults[4])) {
      fetchRequest(4, heating_spanInputRef.current.value)
    } if (Number(heating_priceInputRef.current.value) !== Number(defaults[14])) {
      fetchRequest(14, heating_priceInputRef.current.value)
    } if (Number(elec_spanInputRef.current.value) !== Number(defaults[1])) {
      fetchRequest(1, elec_spanInputRef.current.value)
    } if (Number(elec_priceInputRef.current.value) !== Number(defaults[11])) {
      fetchRequest(11, elec_priceInputRef.current.value)
    } if (Number(vent_spanInputRef.current.value) !== Number(defaults[8])) {
      fetchRequest(8, vent_spanInputRef.current.value)
    } if (Number(vent_priceInputRef.current.value) !== Number(defaults[18])) {
      fetchRequest(18, vent_priceInputRef.current.value)
    } if (Number(front_spanInputRef.current.value) !== Number(defaults[3])) {
      fetchRequest(3, front_spanInputRef.current.value)
    } if (Number(front_priceInputRef.current.value) !== Number(defaults[13])) {
      fetchRequest(13, front_priceInputRef.current.value)
    } if (Number(yard_spanInputRef.current.value) !== Number(defaults[9])) {
      fetchRequest(9, yard_spanInputRef.current.value)
    } if (Number(yard_priceInputRef.current.value) !== Number(defaults[19])) {
      fetchRequest(19, yard_priceInputRef.current.value)
    } if (Number(roof_spanInputRef.current.value) !== Number(defaults[6])) {
    } if (Number(yard_cover_spanInputRef.current.value) !== Number(defaults[24])) {
      fetchRequest(9, yard_cover_spanInputRef.current.value)
    } if (Number(yard_cover_priceInputRef.current.value) !== Number(defaults[23])) {
      fetchRequest(19, yard_cover_priceInputRef.current.value)
    } if (Number(roof_spanInputRef.current.value) !== Number(defaults[6])) {
      fetchRequest(6, roof_spanInputRef.current.value)
    } if (Number(roof_priceInputRef.current.value) !== Number(defaults[16])) {
      fetchRequest(16, roof_priceInputRef.current.value)
    } if (Number(elevator_spanInputRef.current.value) !== Number(defaults[2])) {
      fetchRequest(2, elevator_spanInputRef.current.value)
    } if (Number(elevator_priceInputRef.current.value) !== Number(defaults[12])) {
      fetchRequest(12, elevator_priceInputRef.current.value)
    } if (Number(rateInputRef.current.value) !== Number(defaults[28])) {
      fetchRequest(28, parseFloat(rateInputRef.current.value.replace(",", ".")) * 1000)
    } setTimeout(() => window.location.reload(), 1000);
  }

  return (
    <div>
      <form onSubmit={saveEdit}>
        <table id="defaults" className={classes.local} style={{width: "100%"}}>
          <thead>
            <tr>
              <th></th>
              <th>Elinikä</th>
              <th>Hinta</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Viemäriputket</th>
              <td><input
                type="number"
                name="sewer_span"
                autoComplete="off"
                defaultValue={defaults[7]}
                ref={sewer_spanInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
              <td><input
                type="number"
                name="sewer_price"
                autoComplete="off"
                defaultValue={defaults[17]}
                ref={sewer_priceInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
            </tr>
            <tr>
              <th>Käyttövesiputket</th>
              <td><input
                type="number"
                name="plumbing_span"
                autoComplete="off"
                defaultValue={defaults[5]}
                ref={plumbing_spanInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
              <td><input
                type="number"
                name="plumbing_price"
                autoComplete="off"
                defaultValue={defaults[15]}
                ref={plumbing_priceInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
            </tr>
            <tr>
              <th>Märkätilat</th>
              <td><input
                type="number"
                name="bathroom_span"
                autoComplete="off"
                defaultValue={defaults[0]}
                ref={bathroom_spanInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
              <td><input
                type="number"
                name="bathroom_price"
                autoComplete="off"
                defaultValue={defaults[10]}
                ref={bathroom_priceInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
            </tr>
            <tr>
              <th>Lämmitysputkisto ja -varaaja</th>
              <td><input
                type="number"
                name="heating_span"
                autoComplete="off"
                defaultValue={defaults[4]}
                ref={heating_spanInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
              <td><input
                type="number"
                name="heating_price"
                autoComplete="off"
                defaultValue={defaults[14]}
                ref={heating_priceInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
            </tr>
            <tr>
              <th>Sähkö- ja telejärjestelmä</th>
              <td><input
                type="number"
                name="elec_span"
                autoComplete="off"
                defaultValue={defaults[1]}
                ref={elec_spanInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
              <td><input
                type="number"
                name="elec_price"
                autoComplete="off"
                defaultValue={defaults[11]}
                ref={elec_priceInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
            </tr>
            <tr>
              <th>Ilmanvaihto ja hormit</th>
              <td><input
                type="number"
                name="vent_span"
                autoComplete="off"
                defaultValue={defaults[8]}
                ref={vent_spanInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
              <td><input
                type="number"
                name="vent_price"
                autoComplete="off"
                defaultValue={defaults[18]}
                ref={vent_priceInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
            </tr>
            <tr>
              <th>Julkisivut, ikkunat, parvekkeet</th>
              <td><input
                type="number"
                name="front_span"
                autoComplete="off"
                defaultValue={defaults[3]}
                ref={front_spanInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
              <td><input
                type="number"
                name="front_price"
                autoComplete="off"
                defaultValue={defaults[13]}
                ref={front_priceInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
            </tr>
            <tr>
              <th>Salaojat ja sokkeli</th>
              <td><input
                type="number"
                name="yard_span"
                autoComplete="off"
                defaultValue={defaults[9]}
                ref={yard_spanInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
              <td><input
                type="number"
                name="yard_price"
                autoComplete="off"
                defaultValue={defaults[19]}
                ref={yard_priceInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
            </tr>
            <tr>
              <th>Pihakansi</th>
              <td><input
                type="number"
                name="yard_cover_span"
                autoComplete="off"
                defaultValue={defaults[24]}
                ref={yard_cover_spanInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
              <td><input
                type="number"
                name="yard_cover_price"
                autoComplete="off"
                defaultValue={defaults[23]}
                ref={yard_cover_priceInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
            </tr>
            <tr>
              <th>Katto</th>
              <td><input
                type="number"
                name="roof_span"
                autoComplete="off"
                defaultValue={defaults[6]}
                ref={roof_spanInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
              <td><input
                type="number"
                name="roof_price"
                autoComplete="off"
                defaultValue={defaults[16]}
                ref={roof_priceInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
            </tr>
            <tr>
              <th>Hissi</th>
              <td><input
                type="number"
                name="elevator_span"
                autoComplete="off"
                defaultValue={defaults[2]}
                ref={elevator_spanInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
              <td><input
                type="number"
                name="elevator_price"
                autoComplete="off"
                defaultValue={defaults[12]}
                ref={elevator_priceInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
            </tr>
            <tr>
              <th>Korko</th>
              <td></td>
              <td><input
                type="float"
                name="rate"
                autoComplete="off"
                defaultValue={defaults[28] / 1000}
                ref={rateInputRef}
                style={{ backgroundColor: "transparent", border: "none" }}
              /></td>
            </tr>
          </tbody>
        </table>
        <button style={{ marginLeft: "0" }}>Tallenna</button>
      </form>
    </div >
  );
}
